import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private keys = '13012409200512120518';
  constructor() {}

  /**
   *
   *
   * @param value
   * @param [keys=null]
   * @returns encrypted string
   * @memberof CrytoService
   */
  encrypt(value: string, keys: string = null): string {
    let key: CryptoJS.lib.WordArray;
    let iv: CryptoJS.lib.WordArray;
    if (keys) {
      key = CryptoJS.enc.Utf8.parse(keys);
      iv = CryptoJS.enc.Utf8.parse(keys);
      CryptoJS.enc.Utf8.parse(keys);
    } else {
      key = CryptoJS.enc.Utf8.parse(this.keys);
      iv = CryptoJS.enc.Utf8.parse(this.keys);
    }
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  /**
   *
   *
   * @param value
   * @param [keys=null]
   * @returns decrypted string
   * @memberof CrytoService
   */
  decrypt(value: string, keys: string = null): string {
    let key: CryptoJS.lib.WordArray;
    let iv: CryptoJS.lib.WordArray;
    if (keys) {
      key = CryptoJS.enc.Utf8.parse(keys);
      iv = CryptoJS.enc.Utf8.parse(keys);
      CryptoJS.enc.Utf8.parse(keys);
    } else {
      key = CryptoJS.enc.Utf8.parse(this.keys);
      iv = CryptoJS.enc.Utf8.parse(this.keys);
    }
    const decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
