import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ApiService } from './services/api/api.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { ScreenSizeService } from './services/screen-size/screen-size.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private platform = inject(Platform);
  private apiService = inject(ApiService);
  private screenSizeService = inject(ScreenSizeService);
  constructor() {
    this.initializeApp().then(async () => {
      setTimeout(async () => {
        await SplashScreen.hide();
      }, 3000);
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      await this.apiService.getAppVersion();
      await this.apiService.getInfo();
      await this.apiService.getDeviceId();
      const isAuthenticated = await this.apiService.checkToken();
      if (isAuthenticated) {
        await this.apiService.getAuthenticatedToken();
        this.apiService.getAuthenticatedUser();
        this.apiService.fetchUserDetails().subscribe();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSizeService.onResize(
      event.target.innerWidth,
      event.target.innerHeight
    );
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    this.screenSizeService.onResize(
      event.target.innerWidth,
      event.target.innerHeight
    );
  }

  async ngOnInit() {}

  private async initializeApp() {
    return await this.platform.ready().then(async () => {
      this.screenSizeService.onResize(
        this.platform.width(),
        this.platform.height()
      );
      // // Okay, so the platform is ready and our plugins are available.
      // // Here you can do any higher level native things you might need.
      // await this.apiService.getAppVersion();
      // await this.apiService.getInfo();
      // await this.apiService.getDeviceId();
      // const isAuthenticated = await this.apiService.checkToken();
      // if (isAuthenticated) {
      //   await this.apiService.getAuthenticatedToken();
      //   this.apiService.getAuthenticatedUser();
      // }
    });
  }
}
