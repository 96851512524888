/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api/api.service';
import { AppUpdateDialogComponent } from '../components/app-update-dialog/app-update-dialog.component';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authorizationExcludedUrls = [
      `${environment.baseUrl}login`,
      `${environment.baseUrl}signup`,
      `${environment.baseUrl}forgot-password`,
      `${environment.baseUrl}reset-password`,
    ];

    if (authorizationExcludedUrls.indexOf(req.url) === -1) {
      // send cloned request with header to the next handler.
      return next
        .handle(this.addHeaders(req, this.apiService.accessToken))
        .pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (event.status === 204) {
                this.openAppUpdateModal();
              }
            }
          }),
          catchError(this.httpErrorHandler)
        );
    }

    return next
      .handle(this.addHeaders(req))
      .pipe(catchError(this.httpErrorHandler));
  }

  httpErrorHandler = (error: HttpErrorResponse) => {
    if (error.status === 401) {
      this.apiService.logout();
    }
    let message = '';
    if (typeof error.error.message === 'object') {
      // eslint-disable-next-line guard-for-in
      for (const messageKeys in error.error.message) {
        if (Array.isArray(error.error.message[messageKeys])) {
          message += error.error.message[messageKeys].join(' ');
        }
        if (typeof error.error.message[messageKeys] === 'string') {
          message += error.error.message[messageKeys];
        }
        message += ' ';
      }
      error.error.message = message;
    }
    // eslint-disable-next-line arrow-body-style
    return throwError(() => {
      return {
        message: error.error.message,
        success: error.error.success,
        code: error.error.code,
      };
    });
  };

  private addHeaders(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    let params = new HttpParams();
    const os =
      this.apiService.deviceInfo.platform !== 'ios' ? 'android' : 'ios';
    if (this.apiService.deviceInfo.platform !== 'web') {
      params = params
        .set('os', os)
        .set(
          'version',
          this.apiService.appVersion ?? environment.appVersionNumber
        );
    }
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    });

    if (!token) {
      headers = headers.delete('Authorization');
    }

    return req.clone({ headers, params });
  }

  private async openAppUpdateModal() {
    const modal = await this.apiService.openComponent(
      AppUpdateDialogComponent,
      {},
      'APP_UPDATE_DIALOG',
      '',
      false
    );
  }
}
