import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkService } from '../network/network.service';

import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { CryptoService } from '../crypto/crypto.service';
import { Device, DeviceInfo } from '@capacitor/device';
import { IUserDetails } from 'src/app/interfaces/app.interface';
import { App } from '@capacitor/app';
import { ComponentProps, ComponentRef } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class AppCoreService {
  apiBaseUrl: string;
  authenticationState = new BehaviorSubject(false);
  accessToken: string;
  deviceInfo: DeviceInfo;
  deviceId: string;
  appVersion: string;
  maxiTellerUser = new BehaviorSubject<IUserDetails>(null);
  activeModals: string[] = [];

  public headers: HttpHeaders = new HttpHeaders();
  constructor(
    public platform: Platform,
    public http: HttpClient,
    public storage: Storage,
    public networkService: NetworkService,
    public cryptoService: CryptoService,
    public navController: NavController,
    public modalController: ModalController
  ) {
    this.apiBaseUrl = environment.baseUrl;
  }

  /**
   * It gets the device information and returns it.
   *
   * @returns The device information.
   */
  async getInfo(): Promise<DeviceInfo> {
    this.deviceInfo = await Device.getInfo();
    return this.deviceInfo;
  }

  async getDeviceId() {
    const { identifier } = await Device.getId();
    this.deviceId = identifier;
    return identifier;
  }

  async getAppVersion() {
    if (this.deviceInfo && this.deviceInfo?.platform !== 'web') {
      const appInfo = await App.getInfo();
      this.appVersion = appInfo.build;
    }
  }

  /**
   * dbInit
   */
  dbInit() {
    this.storage.create();
    this.storage.defineDriver(CordovaSQLiteDriver);
  }

  /**
   * It gets the token from the storage
   *
   *
   * @returns A promise that resolves to a auth token string.
   */
  async getAuthenticatedToken(): Promise<string> {
    return await this.storage.get('maxitellerAccessToken').then((res) => {
      if (res != null) {
        return new Promise((resolve, reject) => {
          try {
            this.accessToken = this.cryptoService.decrypt(res, this.deviceId);
            resolve(this.accessToken);
          } catch (e) {
            this.accessToken = null;
            this.authenticationState.next(false);
            reject(e);
          }
        });
      } else {
        return new Promise((reject) => {
          const e = `Auth Token is undefined`;
          this.accessToken = null;
          this.authenticationState.next(false);
          reject(e);
        });
      }
    });
  }

  /**
   * It checks if the token is stored in the local storage, if it is, it decrypts it and returns true, if
   * not, it returns false
   *
   * @returns A boolean value.
   */
  async checkToken(): Promise<boolean> {
    return await this.storage.get('maxitellerAccessToken').then((res) => {
      if (res != null) {
        this.authenticationState.next(true);
        return true;
      } else {
        this.authenticationState.next(false);
        return false;
      }
    });
  }

  /**
   * It gets the user's token data from the local storage, decrypts it, and returns the decrypt data as
   * a promise
   *
   * @returns The user's information.
   */
  async getAuthenticatedUser(emitUser = true): Promise<IUserDetails> {
    return await this.storage.get('maxitellerUser').then((res) => {
      if (res != null) {
        const user = JSON.parse(this.cryptoService.decrypt(res, this.deviceId));
        if (emitUser) {
          this.maxiTellerUser.next(user);
        }
        return new Promise((resolve) => {
          resolve(user);
        });
      }
    });
  }

  /**
   * It returns a promise that resolves to a boolean value
   *
   * @returns A boolean value.
   */
  async isAuthenticated(): Promise<boolean> {
    return this.authenticationState.value;
  }

  async setAuthenticatedToken(token: string) {
    this.accessToken = token;
    await this.storage.set(
      'maxitellerAccessToken',
      this.cryptoService.encrypt(token, this.deviceId)
    );
  }

  async setAuthenticatedUser(user: any) {
    await this.storage.set(
      'maxitellerUser',
      this.cryptoService.encrypt(JSON.stringify(user), this.deviceId)
    );
    this.maxiTellerUser.next(user);
  }

  storeModal(modalId: string) {
    const modalIndex = this.activeModals.indexOf(modalId);
    if (modalIndex === -1) {
      this.activeModals.push(modalId);
    }
  }

  removeModal(modalId: string) {
    const modalIndex = this.activeModals.indexOf(modalId);
    if (modalIndex > -1) {
      this.activeModals.splice(modalIndex, 1);
    }
  }

  closeAllModals() {
    this.activeModals.forEach((element) => {
      this.modalController.dismiss(null, null, element);
    });
  }

  async openComponent(
    component: ComponentRef,
    componentProps: ComponentProps = {},
    id: string,
    cssClass: string,
    backdropDismiss = true
  ) {
    this.storeModal(id);
    const modal = await this.modalController.create({
      component,
      backdropDismiss,
      componentProps,
      animated: false,
      cssClass,
      id,
    });
    await modal.present();
    return modal;
  }

  /**
   * It removes the token from storage, sets the always logged in mode to false, sets the authentication
   * state to false, clears the user data state, and clears the local storage
   *
   * @returns The promise is being returned.
   */
  async logout() {
    await this.storage.remove('maxitellerAccessToken');
    await this.storage.remove('maxitellerUser');
    this.authenticationState.next(false);
    this.closeAllModals();
    this.navController.navigateRoot('/auth/login');
  }
}
