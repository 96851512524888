import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ApiService } from './services/api/api.service';
import { Capacitor } from '@capacitor/core';
import { IUserDetails } from './interfaces/app.interface';

const canMatchOnboard = async () => {
  const navController = inject(NavController);
  if (Capacitor.getPlatform() === 'web') {
    return navController.navigateRoot(['/dashboard/home']);
  }
  return true;
};
const canMatchAuthInverse = async () => {
  const navController = inject(NavController);
  const apiService = inject(ApiService);
  return await apiService.storage.get('maxitellerAccessToken').then((res) => {
    console.log(res);
    if (res !== null) {
      return navController.navigateRoot(['/dashboard/home']);
    }
    return true;
  });
};
const canMatchAuth = async () => {
  const navController = inject(NavController);
  const apiService = inject(ApiService);
  return await apiService.storage
    .get('maxitellerAccessToken')
    .then(async (res) => {
      const user: IUserDetails = await apiService.getAuthenticatedUser(false);
      // console.log(user);
      if (res !== null) {
        return true;
      }
      return navController.navigateRoot(['/auth/login']);
    });
};

const routes: Routes = [
  {
    path: 'onboard',
    loadChildren: () =>
      import('./pages/onboard/onboard.module').then((m) => m.OnboardPageModule),
    canLoad: [canMatchAuthInverse, canMatchOnboard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
    canLoad: [canMatchAuthInverse],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canLoad: [canMatchAuth],
  },
  {
    path: '',
    redirectTo: 'onboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
