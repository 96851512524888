import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { Toast } from '@capacitor/toast';
import { DeviceInfo, Device } from '@capacitor/device';

import { IReqDefaultResponse } from '../../interfaces/req-default-response';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  deviceInfo = new BehaviorSubject<DeviceInfo>(null);
  referralDetails = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private loadingController: LoadingController,
    private toastCtrl: ToastController
  ) {}

  async getDeviceInfo() {
    const info = await Device.getInfo();
    this.deviceInfo.next(info);
  }

  async getLoader() {
    return await this.loadingController.create({
      spinner: null,
      message: '<ion-spinner name="lines-small"></ion-spinner>',
      translucent: true,
      mode: 'md',
      cssClass: 'custom-loading',
    });
  }

  async presentToast(
    message: string,
    duration: number,
    color: string,
    position
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      color,
      position,
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await toast.present();
  }

  async showToast(
    text: string,
    duration: 'short' | 'long',
    position?: 'top' | 'center' | 'bottom'
  ) {
    await Toast.show({
      text,
      duration,
      position,
    });
  }

  async showAlert(header: string, message: string) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      mode: 'ios',
      cssClass: 'mt-alert',
      buttons: [
        {
          text: 'Okay',
          cssClass: 'mt-btn only',
        },
      ],
    });
    await alert.present();
  }

  fetchVtpassVariationCodes(serviceId: string) {
    return this.http.get<IReqDefaultResponse>(
      `${environment.baseUrl}client/variation-codes?serviceType=${serviceId}`
    );
  }

  fetchReferralDetails() {
    return this.http
      .get<IReqDefaultResponse>(`${environment.baseUrl}client/profile/referral`)
      .pipe(
        tap((resp) => {
          if (resp.success) {
            this.referralDetails.next(resp.data.myreferral);
          }
        })
      );
  }

  fetchNotice() {
    return this.http.get<IReqDefaultResponse>(
      `${environment.baseUrl}client/notice`
    );
  }

  fetchNotices() {
    return this.http.get<IReqDefaultResponse>(
      `${environment.baseUrl}client/notices`
    );
  }

  /**
   *
   *
   * @param promise
   * @param [ms=20000]
   * @returns
   * @memberof ApiService
   */
  promiseTimeout(promise: Promise<any>, ms: number = 40000): Promise<any> {
    // Create a promise that rejects in <ms> milliseconds
    const timeout = new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        const error = {
          code: 1,
          result: 'error',
          error: 'Timeout',
          message:
            'We are unable to process you request as the time, kindly try again later...',
        };
        reject(error);
      }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
  }

  formatAmount(amount: string = '', prefix: string = '₦') {
    const x = parseFloat(amount.split(',').join(''));
    return (
      prefix +
      new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(x)
    );
  }

  sanitizeObjectData(obj: any) {
    obj = { ...obj };
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  requestQueryParamsResolver(queryParamObj: any): string {
    let queryParamString = '?';
    // eslint-disable-next-line guard-for-in
    for (const key in queryParamObj) {
      if (queryParamObj[key]) {
        queryParamString += `${key}=${queryParamObj[key]}&`;
      }
    }
    return queryParamString.substring(0, queryParamString.length - 1);
  }
}
