import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-app-update-dialog',
  templateUrl: './app-update-dialog.component.html',
  styleUrls: ['./app-update-dialog.component.scss'],
})
export class AppUpdateDialogComponent implements OnInit {
  downloadLink: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    if (Capacitor.getPlatform() === 'android') {
      this.downloadLink =
        'https://play.google.com/store/apps/details?id=com.maxiteller.app';
    } else if (Capacitor.getPlatform() === 'ios') {
      this.downloadLink =
        'https://apps.apple.com/us/app/maxiteller/id1611077456';
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async gotoDownloadStore() {
    await Browser.open({ url: this.downloadLink });
    this.dismissModal();
  }
}
