/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Observable, tap } from 'rxjs';
import {
  IApiErrorResponse,
  IApiGenericResponseInfo,
  IAppVersionResponse,
} from 'src/app/interfaces/api-response.interface.d';
import { environment } from 'src/environments/environment';
import { AppCoreService } from '../app-core/app-core.service';
import { CryptoService } from '../crypto/crypto.service';
import { NetworkService } from '../network/network.service';
import { UtilService } from '../util/util.service';
import { IReservedAccount } from 'src/app/interfaces/app.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends AppCoreService {
  private utilService = inject(UtilService);
  /**
   * Creates an instance of ApiService.
   *
   *
   * @param platform
   *
   * @param http
   *
   * @param alertController
   *
   * @param toastController
   * @memberof ApiService
   */
  constructor(
    platform: Platform,
    http: HttpClient,
    storage: Storage,
    networkService: NetworkService,
    cryptoService: CryptoService,
    navController: NavController,
    modalController: ModalController
  ) {
    super(
      platform,
      http,
      storage,
      networkService,
      cryptoService,
      navController,
      modalController
    );
    this.dbInit();
    this.getDeviceId();
  }

  // Authentications
  login(param: any) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}login`,
      param
    );
  }

  register(param: any) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}signup`,
      param
    );
  }

  forgotPassword(email: string) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}forgot-password`,
      { email }
    );
  }

  resetPassword(param: any) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}reset-password`,
      param
    );
  }

  verifyEmailOtp(param: any) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}verify-email-otp`,
      param
    );
  }

  resendEmailOtp(email: string) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}send-email-otp`,
      { email }
    );
  }

  // Profile
  createUserDetails(param: any) {
    return this.http
      .post<IApiGenericResponseInfo>(`${this.apiBaseUrl}users/create-details`, {
        ...param,
      })
      .pipe(
        tap((resp) => {
          if (resp.success) {
            this.setAuthenticatedUser(resp.data?.userDetails);
          }
        })
      );
  }
  fetchUserDetails() {
    return this.http
      .get<IApiGenericResponseInfo>(`${this.apiBaseUrl}users/details`)
      .pipe(
        tap((resp) => {
          if (resp?.success) {
            const reservedAccount = this.maxiTellerUser.value.reservedAccount;
            this.setAuthenticatedUser({
              ...resp.data?.userDetails,
              reservedAccount,
            });
          }
        })
      );
  }
  updateUserDetails(param: any) {
    return this.http.patch<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/update-details`,
      {
        ...param,
      }
    );
  }

  bvnVerification(param: any) {
    return this.http.patch<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/verify-bvn-nuban`,
      {
        ...param,
      }
    );
  }

  upgradeAccount() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/upgrade`
    );
  }

  generateAPIKey(security_pin: string) {
    return this.http.patch<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/generate-key`,
      { security_pin }
    );
  }

  reGenerateAPIKey(security_pin: string) {
    return this.http.patch<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/regenerate-key`,
      { security_pin }
    );
  }

  deleteAccount(security_pin: string) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/user/delete`,
      { security_pin }
    );
  }

  // Annoucements
  fetchImageAnnoucements() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}slider/images`
    );
  }

  fetchTextFeeds() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}feed/texts`
    );
  }

  // VTPASS discount
  fetchVtPassDiscount(code: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}vt/discounts/${code}`
    );
  }

  // Airtime
  fetchAirtimeNetworks() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}airtime/networks`
    );
  }

  buyAirtme(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}airtime/buy`,
      { ...payload }
    );
  }

  // Data
  fetchDataNetworks() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}data/networks`
    );
  }
  fetchDataPlans(network_id: number) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}data/plans`,
      { network_id }
    );
  }
  buyData(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}data/buy`,
      { ...payload }
    );
  }

  // International Top Up
  fetchCountries() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}inter/countries`
    );
  }

  fetchProductTypes(countryCode: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}inter/products/${countryCode}`
    );
  }

  fetchForeignOperators(countryCode: string, productTypeId: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}inter/operators/${countryCode}/${productTypeId}`
    );
  }

  fetchForeignTopUpVariations(
    countryCode: string,
    productTypeId: string,
    operatorId: string
  ) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}inter/variations/country/${countryCode}/product/${productTypeId}/operator/${operatorId}`
    );
  }

  purchaseForeignTopUp(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}inter/pay-service`,
      { ...payload }
    );
  }

  // Electricity
  fetchElectricityDiscos() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}electricity-discos`
    );
  }
  verifyMeterNumber(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${environment.baseUrl}verify-meter`,
      { ...payload }
    );
  }
  payElectricity(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}pay-electricity`,
      { ...payload }
    );
  }

  // Education
  fetchExamTypes() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}list-exams`
    );
  }
  fetchExamVariations(code: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}exam-variations/${code}`
    );
  }
  fetchPremiumExamInfo(code: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}saved-exams/${code}`
    );
  }
  verifyJambProfileID(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${environment.baseUrl}verify-profile`,
      { ...payload }
    );
  }
  payJambWaecEducation(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}buy-jamb-waec`,
      { ...payload }
    );
  }
  payNecoEducation(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}buy-neco`,
      { ...payload }
    );
  }

  // Cable TV
  fetchDecoderTypes() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}tv/decoder-types`
    );
  }
  fetchDecoderTypeVariations(decoder: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}tv/variations/${decoder}`
    );
  }
  verifyDecoder(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${environment.baseUrl}tv/verify-decoder`,
      { ...payload }
    );
  }
  makeCableTVPayment(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${environment.baseUrl}tv/pay`,
      { ...payload }
    );
  }

  // Security
  createSecurityPin(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/create-pin`,
      { ...payload }
    );
  }
  updateSecurityPin(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/update-security-pin`,
      { ...payload }
    );
  }
  changePassword(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/change-password`,
      { ...payload }
    );
  }
  sendPINOTP() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/send-pin-otp`
    );
  }
  resetSecurityPin(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/reset-pin`,
      { ...payload }
    );
  }

  // BankDetails
  fetchBankDetails() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/bank-details`
    );
  }
  addBankDetails(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/create-bank-details`,
      { ...payload }
    );
  }
  deleteBankDetails() {
    return this.http.delete<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/bank-details`
    );
  }
  fetchReservedAccount() {
    return this.http
      .get<IApiGenericResponseInfo>(`${this.apiBaseUrl}users/reserved-account`)
      .pipe(
        tap((resp) => {
          if (resp.success) {
            const user = this.maxiTellerUser.value;
            const reservedAccount: IReservedAccount = {
              accountName: resp.data?.acctName,
              accountNumber: resp.data?.accountNo,
              bankName: resp.data?.bank,
            };
            this.setAuthenticatedUser({ ...user, reservedAccount });
          }
        })
      );
  }
  fetchNubanBankCodes() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}bank_codes`
    );
  }
  fetchNubanAccountName(bankCode: string, accountNumber: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/nuban-detail?bank_code=${bankCode}&account_number=${accountNumber}`
    );
  }

  // Reward Points
  fetchRewardPoints() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/points`
    );
  }
  redeemRewardPoints() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/points/redeem`
    );
  }

  // Reward Points
  fetchReferrals(page: number) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/referrals?page=${page}`
    );
  }
  claimReferralEarnings(referralId: number) {
    return this.http.patch<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/referrals/claim/${referralId}`,
      {}
    );
  }

  // Sell Airtime
  fetchConvertsHistory() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}converts-history`
    );
  }
  fetchConvertOrderSummary(id: number) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}convert-summary/${id}`
    );
  }
  fetchNetworks() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}convert-airtime-networks`
    );
  }
  sellAirtime(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}convert-airtime`,
      { ...payload }
    );
  }
  confirmConvertTransferStatus(
    id: number,
    transfer_status: 'transferred' | 'cancelled'
  ) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}convert/${id}/status`,
      { transfer_status }
    );
  }

  // Gift Cards
  fetchUserGiftCards(page?: number) {
    const pageParam = page ? `?page=${page}` : '';
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}giftcards/list${pageParam}`
    );
  }
  fetchGiftCardCode(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}giftcards/show/card`,
      { ...payload }
    );
  }
  fetchGiftCards(queryParamObj: any) {
    const queryParams =
      this.utilService.requestQueryParamsResolver(queryParamObj);
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}giftcards/products${queryParams}`
    );
  }
  fetchGiftCardCountries() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}giftcards/countries`
    );
  }
  fetchGiftCardExchangeRates(code: string) {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}giftcards/rates/currency/${code}`
    );
  }
  buyGiftCard(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}giftcards/buy`,
      { ...payload }
    );
  }

  // Virtual Card
  fetchVirtualCard() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}users/my-virtual-card`
    );
  }
  fetchCurrentExchangeRate() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}current-rates/currency/usd`
    );
  }
  createVirtualCard(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}order-virtual-card`,
      { ...payload }
    );
  }
  fundVirtualCard(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}fund-card`,
      { ...payload }
    );
  }
  showVirtualCardDetails(security_pin: string) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}card-balance`,
      { security_pin }
    );
  }
  deleteVirtualCard(security_pin: string) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}terminate-card`,
      { security_pin }
    );
  }

  // Wallet
  initWalletFunding(params) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}fund-wallet`,
      {
        ...params,
      }
    );
  }
  fetchPaymentTransactions() {
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}payments?per_page=3`
    );
  }
  walletWithdrawal(payload) {
    return this.http.post<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}wallet/withdraw`,
      {
        ...payload,
      }
    );
  }

  // Transactions
  fetchTransactions(params: any) {
    const queryParams = this.utilService.requestQueryParamsResolver(params);
    return this.http.get<IApiGenericResponseInfo>(
      `${this.apiBaseUrl}filter-transactions${queryParams}`
    );
  }
}
